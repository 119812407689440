import RegWindow from "../RegWindow/RegWindow.jsx";
import TeamIndividual from "../TeamIndividual/TeamIndividual.jsx";
//import DropDown from '../TeamIndividual/DropDown.js';
import React from "react";
import "../../App.css";
import "./Registration.css";
import validator from "validator";

function Registration(props) {
  const [formData, setFormData] = React.useState({
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    college: "",
    city: "",
    state: "",
  });
  const [team, setTeam] = React.useState([{ n: "" }, { n: "" }]);
  const [personName, setPersonName] = React.useState([]);

  const [isTeamShowing, setIsTeamShowing] = React.useState(false);

  function handleSubmit() {

    const date = new Date();
    var ISToffSet = 330; //IST is 5:30; i.e. 60*5+30 = 330 in minutes 
    var ISTTime = new Date(date.getTime()+ISToffSet*60*1000);


    if (
      formData.fullName !== "" && formData.email !== "" && formData.phone !== "" && formData.gender !== "" && formData.college !== "" && formData.city !== "" && formData.state !== "" && isTeamShowing
        ? !team.slice(0, 2).some((eachMemberObj) => eachMemberObj.n === "")
        : true && personName?.length !== 0
    ) {
      if (!validator.isEmail(formData.email)) {
        alert("Please Enter Your Email.");
        return;
      } else if (!validator.isMobilePhone(formData.phone, "en-IN")) {
        alert("Please enter a valid phone number!");
        return;
      }
      let strs = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
      for (let i = 0; i < team.length; i++) {
        strs[i] = team[i].n;
      }

      const event_data = localStorage.getItem("events");
      const event_str = JSON.parse(event_data);
      console.log(event_str[1]);
      let final_event_str = "";
      for (let i = 0; i < event_str.length; i++) {
        if (i === 0) {
          final_event_str = final_event_str + event_str[i];
        } else {
          final_event_str = final_event_str + "," + event_str[i];
        }
      }

      let usr_data = {
        Submit_Time: ISTTime,
        Name: formData.fullName,
        Email: formData.email,
        Phone_Number: formData.phone,
        Gender: formData.gender,
        State_UT: formData.state,
        College_Name: formData.college,
        Event: final_event_str,
        Member1: strs[0],
        Member2: strs[1],
        Member3: strs[2],
        Member4: strs[3],
        Member5: strs[4],
        Member6: strs[5],
        Member7: strs[6],
        Member8: strs[7],
        Member9: strs[8],
        Member10: strs[9],
        Member11: strs[10],
        Member12: strs[11],
        Member13: strs[12],
        Member14: strs[13],
        Member15: strs[14],
        Member16: strs[15],
        Member17: strs[16],
        Member18: strs[17],
        Member19: strs[18],
        Member20: strs[19],
        Member21: strs[20],
        Member22: strs[21],
        Member23: strs[22],
        Member24: strs[23],
        Member25: strs[24],
      };

      if (isTeamShowing === false) {
        fetch("https://sheet.best/api/sheets/23d4633a-90d6-48d3-aaf7-c6479a2f8e55", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(usr_data),
        })
          .then((r) => r.json())
          .then((data) => {
            // The response comes here
            console.log(data);
          })
          .catch((error) => {
            // Errors are reported there
            console.log(error);
          });
      } else {
        fetch("https://sheet.best/api/sheets/c8bb7293-74da-4a36-93e6-9d3c59cb7a2e", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(usr_data),
        })
          .then((r) => r.json())
          .then((data) => {
            // The response comes here
            console.log(data);
          })
          .catch((error) => {
            // Errors are reported there
            console.log(error);
          });
      }
      window.open("https://www.onlinesbi.sbi/sbicollect/icollecthome.htm","_blank")
      alert(`Congratulations ${usr_data.Name}! You have been registered for Waves '23`);
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        gender: "",
        college: "",
        city: "",
        state: "",
      });
      setPersonName([]);
      setTeam([{ n: "" }, { n: "" }]);
      console.log(usr_data);
      props.close();
      props.reg_success();
    } else {
      alert("Please fill all the required fields!");
    }
  }

  return (
    <div className="container">
      <div
        className="regmodal"
        style={{
          display: "flex",
          justifyContent: "center",
          background: "#393939"
        }}
      >
        <h1 className="reg-heading">Registration</h1>
        <RegWindow formData={formData} setFormData={setFormData} />
        <div className="line"></div>
        <TeamIndividual isTeamShowing={isTeamShowing} setIsTeamShowing={setIsTeamShowing} team={team} setTeam={setTeam} personName={personName} setPersonName={setPersonName} />
      </div>
      <div className="button-holder">
        <div className="register-btn" onClick={handleSubmit}>
          Register
        </div>
      </div>
    </div>
  );
}

export default Registration;
