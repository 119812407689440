import { useState } from "react";
import Queries from "./components/Queries/Queries";
import { styled } from "styled-components";
import cancel_img from "./assets/cancel.png";
import "./App.css";
import Registration from "./components/Registration/Registration";
import rulebook from "./assets/Final23_RULEBOOK.pdf";
import PayDemo from "./assets/Pay_Demo.pdf";
import final_logo from "./assets/final_logo.png";
import reg_suc from "./assets/Registration_Successful.png";

const App = () => {
  const [isShown, setIsShown] = useState(false);
  const handleClickQueries = (event) => {
    setIsShown((current) => !current);
  };
  const [isRegistration, setisRegistration] = useState(false);
  const [registered, setRegistered] = useState(false);
  const handleClickRegistration = (event) => {
    setisRegistration((current) => !current);
  };
  const handleClickPay = () => {
    window.open("https://www.onlinesbi.sbi/sbicollect/icollecthome.htm","_blank")
  }
  const handleClickMood = () => {
    window.open("https://forms.gle/W9CAB1fbwWN24rqi9", "_blank");
  };
  return (
    <div>
      <div className="body">
        <div className="all_logo">
          <div className="logoholder rotate"></div>
          {!registered ? <img src={final_logo} alt="no img" className="logo_img"></img> : <img src={reg_suc} alt="no img" className="reg_suc"></img>}
        </div>
        {/* <div className="logoholder">
          <img src={"Waves_logo.png"} alt="no img" className="logo_img"></img>
        </div> */}
        <button className="button button1" onClick={handleClickRegistration}>
          Register here
        </button>
        <button className="button button2" onClick={handleClickPay}>
          Pay Now
        </button>
        <a href={PayDemo} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
          <button className="button button3">How to Pay</button>
        </a>
        <a href={rulebook} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}>
          <button className="button button4">Rulebook</button>
        </a>
        <button className="button button5" onClick={handleClickMood}>
          Moot Court
        </button>
        <button className="button button6" onClick={handleClickQueries}>
          Queries
        </button>
        {isShown && (
          <div>
            <QueriesBg
              style={{
                background: "black",
                width:"100vw"
              }}
            />
            <Queries2>
              <Query>
                <Close onClick={handleClickQueries}>
                  <img src={cancel_img} alt="" />
                </Close>
                <Queries />
              </Query>
            </Queries2>
          </div>
        )}
        ;
        {isRegistration && (
          <div>
            <RegistrationBg
              style={{
                background: "black",
                width:"100vw"
              }}
            />
            <RegistrationDiv>
              <Close onClick={handleClickRegistration} style={{ position: "relative",marginLeft:"auto",marginRight:"30px" }}>
                <img src={cancel_img} alt="" />
              </Close>
              <Registration close={() => setisRegistration(false)} reg_success={() => setRegistered(true)} />
            </RegistrationDiv>
          </div>
        )}
        ;
      </div>
      <div className="footer">Made with ❤️ by DevSoc</div>
    </div>
  );
};
const Query = styled.div`
  position: absolute;
  right: 13%;
  left: 13%;
  top: 4%;
  display: flex;
  justify-content: center;
  z-index: 10;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;
const Close = styled.button`
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  opacity: 90%;
  z-index: 10;
  @media only screen and (max-width: 550px) {
    width: 30px;
    height: 30px;
    top: 0.6%;
    right: 0.5%;
    }
`;
const RegistrationDiv = styled.div`
  position: absolute;
  right: 5%;
  left: 5%;
  top: 5%;
  bottom: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;
const Queries2 = styled.div`
  position: absolute;
  right: 4%;
  left: 4%;
  top: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`;
const QueriesBg = styled.div`
  position: absolute;
  right: 0%;
  left: 0%;
  top: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 0;
  @media only screen and (max-width: 550px) {
    height: 170vh;
    }
  @media only screen and (min-width: 560px) {
      height: 100vh;
    }
`;
const RegistrationBg = styled.div`
  position: absolute;
  right: 0%;
  left: 0%;
  top: 0%;
  bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 0;
  @media only screen and (max-width: 550px) {
    height: 170vh;
    }
    @media only screen and (min-width: 560px) {
      height: 100vh;
      }
`;
export default App;
