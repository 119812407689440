import React from "react";
import "./RegWindow.css";

const indianStates = [
  "--State or UT--",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

const listIndianStates = indianStates.map((item, key) => (
  <option value={item} key={key}>
    {item}
  </option>
));

export default function RegWindow(props) {
  const { formData, setFormData } = props;

  function handleChange(event) {
    const { name, value } = event.target;
    // const namee = event.target.name;
    // const valuee = event.target.value
    setFormData((old) => ({
      ...old,
      [name]: value,
    }));
  }

  return (
    <div className="RegWindow">
      {/* <div style={{height:'300px'}}></div> */}
      <div>
        <form>
          <input type="text" placeholder="Full Name" onChange={handleChange} name="fullName" value={formData.fullName} required />
          <input type="email" placeholder="Enter Email Address" onChange={handleChange} name="email" value={formData.email} required />
          <input type="text" placeholder="Phone Number" onChange={handleChange} name="phone" value={formData.phone} required />
          <select id="selectGender" value={formData.gender} onChange={handleChange} name="gender" required>
            <option value="">--Gender--</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            {/* <option value="Transgender">Transgender</option> */}
            {/* <option value="Non-binary">Non-binary</option> */}
            <option value="Non-binary">Prefer not to say</option>
          </select>
          <select id="selectState" value={formData.state} onChange={handleChange} name="state" required>
            {listIndianStates}
          </select>
          <input type="text" placeholder="College Name" onChange={handleChange} name="college" value={formData.college} required />
        </form>
      </div>
    </div>
  );
}
