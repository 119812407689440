import * as React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const EventCategories=[
   'Drama', 'Dance', 'Music', 'Beau Vista', 'Carpe Dictum', 'Film & Photography', 'The Quiz Fest', 'Specials'
]

const EventCategories3=['Drama', "Rangmanch","Nukkad Natak","Hear Me Out!",
'Dance',"Natyanjali","Sizzle","Dancing Duo","Nritya Kala",
 'Music', "Indian Rock","Jukebox","Solonote","Rapsody","Silence of the Amps",
 'Beau Vista', "Lets Not Get wasted","Kick Start","Moteef","Artathon", "Blind Art", "Glam Up!", "La Croquis Marquee", "Pixel Vista",
 'Carpe Dictum', "InVerse", "Cultural Gauntlet", "The Lit Quiz", "Word Games", "Melas", "Querencia",
  'Film & Photography', "Sho(r)t",  "Oh Snap", 
  'The Quiz Fest', "Waves Open",  "Mythomagic",  "Vices Quiz", 
  'Specials',"Irshaad","ComicKriya", "Fashion Parade", "Mr and Miss Waves", "Show me the Funny", "Strangely Familiar",
]

const DropDown = ({ isMultiple, personName, setPersonName }) => {
  
  
  const [singleevent,updatesingleevent]=React.useState("")
  
  React.useEffect(()=>{
    console.log(personName)
  }, [personName])
  localStorage.setItem("events", JSON.stringify(personName));
  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
      secondary: {
        main: '#FFFFF',
        light: '#FFFFFF',
        contrastText: '#FFFFFF',
      },
    },
  });
  if(isMultiple){
  return(
    <ThemeProvider theme={theme}>
    <Stack spacing={3} sx={{ width: "320%", input: { color: 'white' }}}>
    <Autocomplete
      multiple={true}
      sx={{input: { margin:"20px",color: 'white','::placeholder':{margin:"20px",color:'white'}}}}
      id="tags-standard"
      options={EventCategories3}
      getOptionLabel={(option) => option}
      getOptionDisabled={(option) =>
        EventCategories.indexOf(option)>-1
      }
      value={personName}
      onChange={(event, value)=>{setPersonName(
        typeof value=== "string" ? value.split(",") : value
      );}}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Events"
          sx={{ input: { margin:"20px",color: 'white','::placeholder':{color:'white',margin:"20px"}}}}
          InputLabelProps={{
            style : {
              color: 'white',
              margin:"20px",
              flex: 1, 
              flexWrap: 'wrap'
            }
        }}
        />
      )}
    />
    </Stack>
    </ThemeProvider>)}
    else{
      return(
        <ThemeProvider theme={theme}>
        <Stack spacing={3} sx={{ width: 145, input: { color: 'white' } }}>
        <Autocomplete
          multiple={false}
          sx={{input: { color: 'white' }}}
          id="tags-standard"
          options={EventCategories3}
          getOptionLabel={(option) => option}
          getOptionDisabled={(option) =>
            EventCategories.indexOf(option)>-1
          }
          value={singleevent}
          onChange={(event, value)=>{setPersonName([value]);
            updatesingleevent(value)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Events"
              placeholder="Events"
              sx={{ input: { color: 'white','::placeholder':{color:'white'}  } }}
              InputLabelProps={{
                style : {
                  color: 'white'
                }
            }}
            />
          )}
        />
        </Stack>
        </ThemeProvider>)
    }
};

export default DropDown;
