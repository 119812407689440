import React from "react";
import "./TeamIndividual.css";
import DropDown from "./DropDown";

const TeamIndividual = (props) => {
  const { team, setTeam, isTeamShowing, setIsTeamShowing, personName, setPersonName } = props;
  /*const [team, setTeam] = useState([
        { n: '' },
        { n: '' },

    ]);

    const [isTeamShowing, setIsTeamShowing] = useState(false)*/

  const handleTeam = (index, field, value) => {
    setTeam((prevTeam) => {
      const newTeam = [...prevTeam];
      newTeam[index][field] = value;
      //console.log(team)
      return newTeam;
    }); //console.log(team)
  };

  const addMember = () => {
    if (team.length < 25) {
      setTeam((prev) => [...prev, { n: "" }]);
    }
  };

  return (
    <div className="Placement">
      <div className="move">
        <div
          onClick={() => {
            setTeam([{ n: "" }, { n: "" }]);
            setIsTeamShowing(false);
            setPersonName([]);
          }}
          className="indvbtn"
          style={{
            cursor: "pointer",
            borderRadius: "999px",
            padding: "10px 20px 10px",
            background: !isTeamShowing && "black",
            color: !isTeamShowing && "white",
          }}
        >
          Individual
        </div>
        <div
          onClick={() => {setIsTeamShowing(true)
            setPersonName([])}
          }
          className="teambtn"
          style={{
            cursor: "pointer",
            borderRadius: "999px",
            padding: "10px 20px 10px",
            background: isTeamShowing && "black",
            color: isTeamShowing && "white",
          }}
        >
          Team
        </div>
      </div>

      {/* <div>
                    {team.map((item, index) => (
                        <div key={index} style={{ padding: '2px' }} >
                            <span style={{ padding: '16px' }}>
                                {item.n}
                            </span>
                            <span>
                                {item.p}
                            </span>
                        </div>
                    ))}
                </div> */}

      <div className="finaltouch">
        {isTeamShowing ? (
          <div className="plsend" >
            <div className="respond"
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 15%",
              }}
            >
              <div>
                <DropDown id="dd" isMultiple={false} personName={personName} setPersonName={setPersonName}/>
              </div>
              {/* <div style={{ textAlign: 'right', display: 'flex', flexDirection: "row-",width:"30vw"  }}> */}

              <div
                className="adder"
                onClick={addMember}
                style={{ marginBottom: "px", alignSelf: "center" }}
              >
                <span>+</span>
              </div>
            </div>
            {/* <Scrollbar> */}
            <div
             className="team-names"
              style={{
                border: "1px solid #a2a2a2",
                height: "80%",
                borderRadius: "6px",
                padding: "0% 2%",
                margin: "10% 15%",
                overflowY: "auto"
              }}
            >
              {team.map((player, index) => (
                <div
                  key={index}
                  style={{ display: "flex", justifyContent: "left" }}
                >
                  <p style={{ color: "white", paddingTop: "1rem" }}>{index+1+"."}</p>
                  <input
                    type="text"
                    name={`n${index}`}
                    value={player.n}
                    onChange={(e) => handleTeam(index, "n", e.target.value)}
                    placeholder="Name"
                    className="input"
                  />
                </div>
              ))}
            </div>

            {/* </Scrollbar> */}
          </div>
        ) : (
          <div
            style={{
              display: "",
              justifyContent: "",
              margin: "auto",
              width: "45vw",
            }}
          >
            <div
              style={{
                width: "auto",
                margin: "auto",
                paddingTop: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "",
              }}
            >
              <div className="over" style={{ alignSelf: "start",width:"30%"}}>
                <DropDown id="dd" isMultiple={true} personName={personName} setPersonName={setPersonName}/>
              </div>
              <div
                style={{
                  width: "40%",
                  height: "150px",
                  borderRadius: "20px",
                  margin: "0 15%",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamIndividual;
