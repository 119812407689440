import React from 'react'
import styled from "styled-components"
import bg from '../../assets/background.png'
import person_image from '../../assets/Waves_Circle.png'
import whatsimg from '../../assets/Whatsapp.png'
import emailimg from '../../assets/email.png'
import phoneimg from '../../assets/phone.png'

function Details() {
  return (
    <Wrapper>
      <Card>
        <Title>
          <p>Registration</p>
        </Title>
        <EventDetails>
          <img src={person_image} alt="" srcset="" />
          <p className='Name'>Rishabh Jain</p>
          <p className='Number'>+919914819587</p>
        </EventDetails>
        <ContactDetails>
          <div className='Whatsapp'><a href='https://wa.me/9914819587'><img src={whatsimg} style={{ width: "85%" }} alt=""/></a></div>
          <div className='Email'><a href="mailto:bitsdeppwaves@gmail.com"><img src={emailimg} alt="" /></a></div>
          <div className='Phone'><a href="tel:+919914819587"><img src={phoneimg} alt="" /></a></div>
        </ContactDetails>
      </Card>
      <Card>
        <Title>
          <p>Events</p>
        </Title>
        <EventDetails>
          <img src={person_image} alt="" srcset="" />
          <p className='Name'>Vybhav Badrinarayanan</p>
          <p className='Number'>+919769331485</p>
        </EventDetails>
        <ContactDetails>
          <div className='Whatsapp'><a href='https://wa.me/9769331485'><img src={whatsimg} style={{ width: "85%" }} alt="" /></a></div>
          <div className='Email'><a href="mailto:events@wavesbits.org"><img src={emailimg} alt="" /></a></div>
          <div className='Phone'><a href="tel:+919769331485"><img src={phoneimg} alt="" /></a></div>
        </ContactDetails>
      </Card>
    </Wrapper>
  )
}
const Wrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
margin: 30px;
@media only screen and (max-width: 500px) {
flex-direction:column;
top:5%;
}
`
const Card = styled.div`
max-width: 500px;
margin:30px;
position:relative;
background-image: url(${bg});
background-size: contain;
display:flex;
justify-content:top;
align-items:center;
flex-direction:column;
@media only screen and (max-width: 550px) {
  margin: 30px;
  }
`;
const Title = styled.div`
margin-top: 2rem;
height:70px;
width:90%;
max-width: 800px;
background:linear-gradient(180deg, rgba(255, 255, 255, 0.47) 0%, rgba(142, 255, 154, 0.03) 100%);
border-radius: 0rem;
box-shadow: 4px 4px 1px 0px rgba(255, 255, 255, 0.25) inset;
box-shadow: 4px 3px 1px 0px rgba(0, 0, 0, 0.25);
display:flex;
justify-content:center;
align-items:center;
p{
  margin:0;
  padding:0;
  font-size:30px;
  font-weight:400;
  font-family: 'Skranji', cursive;
  color:yellow;
}
@media only screen and (max-width: 550px) {
  p{
    font-size:20px;
  }
  }
`
const EventDetails = styled.div`
height:70%;
width:90%;
margin-top:8px;
display:flex;
display:flex;
align-items:center;
flex-direction:column;
img{
  width:85%;
  height:80%;
  object-fit:contain;
  margin-top:10%;
};
.Name{
  margin:0;
  padding: 0px 10px 0px 10px;
  font-size:25px;
  font-weight:400;
  font-family: 'Skranji', cursive;
  color:yellow;
}
.Number{
  font-family: 'Almendra', serif;
  font-size:25px;
  color:white;
  margin:0;
  padding: 0px 10px 0px 10px;
}
@media only screen and (max-width: 550px) {
  .Name{
    font-size:10px;
  }
  .Number{
    font-size:10px;
  }
  }
`
const ContactDetails = styled.div`
width:90%;
display:flex;
align-items:center;
justify-content:space-around;
flex-direction:row;
margin:10px;
.Whatsapp{
  background-color:transparent;
  border:0px;
  width:20%;
  object-fit:contain;
  
}
.Email{
  background-color:transparent;
  border:0px;
  width:21%;
  object-fit:contain;
}
.Phone{
  background-color:transparent;
  border:0px;
  width:14%;
  object-fit:contain;
}
`

export default Details
